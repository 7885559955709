<template>
    <body data-ma-header="blue">
        <appHeader></appHeader>
        <section id="main">        
            <appSidebar></appSidebar>
            <router-view></router-view>
            <appFooter></appFooter>
        </section>
    </body>
</template>
<script>
    export default {
        components: {
            appHeader: () => import("@/components/Header.vue"),
            appSidebar: () => import("@/components/Sidebar.vue"),
            appFooter: () => import("@/components/Footer.vue"),
        },
    }
</script>